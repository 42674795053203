import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

import TablePagination from "./TablePagination";
import { FilterChangedEvent } from "ag-grid-community";

const AdminLogsTable = ({
  data,
  loading,
}: {
  data: any[];
  loading: boolean;
}) => {
  useEffect(() => {
    setRowData(data);
    setIsFiltered(false);
  }, [data]);

  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);

  const columnDefs = useMemo(
    () => [
      { field: "id", headerName: "ID", maxWidth: 120 },
      { field: "action" },
      { field: "field", headerName: "Hierarchy Type" },
      { field: "prev_value", headerName: "Previous Value(s)" },
      { field: "new_value", headerName: "New Value(s)" },
      {
        headerName: "Date",
        valueGetter: (params: any) => new Date(params.data.date),
        valueFormatter: (params: any) =>
          new Date(params.data.date)
            .toLocaleString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
              hour: "numeric",
              minute: "numeric",
            })
            .replace("AM", "a.m.")
            .replace("PM", "p.m."),
        sort: "desc" as const,
        minWidth: 220,
      },
      { field: "user", headerName: "User" },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      filter: true,
      filterParams: { buttons: ["reset"] },
      floatingFilter: true,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true,
      suppressMenu: true,
      icons: {
        sortAscending: "<i class='fa fa-sort-up'/>",
        sortDescending: "<i class='fa fa-sort-down'/>",
        sortUnSort: "<i class='fa fa-sort' style='color:#e3e6f0'></i>",
        filter: "<i class='fa fa-filter'></i>",
      },
    }),
    []
  );

  const onPageSizeChanged = useCallback((e: any) => {
    gridRef.current?.api.paginationSetPageSize(Number(e.target.value));
    setPageSize(Number(e.target.value));
  }, []);

  const onPaginationChanged = useCallback(() => {
    if (gridRef.current?.api) {
      setPageSize(gridRef.current.api.paginationGetPageSize());
      setCurrentPage(gridRef.current.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridRef.current.api.paginationGetTotalPages());
      setTotalEntries(gridRef.current.api.getDisplayedRowCount());
    }
  }, [gridRef]);

  const handleTableSearch = (e: any) => {
    const searchString = e.target.value.toLowerCase().trim();
    gridRef.current?.api.setQuickFilter(searchString);
  };

  const clearAllFilters = useCallback(() => {
    gridRef?.current?.api.setFilterModel(null);
  }, []);

  const onFilterChanged = useCallback((event: FilterChangedEvent) => {
    const filter = gridRef?.current?.api.getFilterModel();
    if (filter) {
      setIsFiltered(Object.keys(filter).length !== 0);
    }
    event.api?.getDisplayedRowCount() === 0
      ? event.api.showNoRowsOverlay()
      : event.api.hideOverlay();
  }, []);

  const overlayNoRowsTemplate = useMemo(
    () => "<span>No results found with these criteria<span>",
    []
  );

  return (
    <>
      <div className="d-flex justify-content-between table-top-container">
        <div className="d-flex align-items-center">
          <label className="d-inline-block">
            Show
            <select
              onChange={onPageSizeChanged}
              className="table-top-input"
              id="page-size"
              value={pageSize}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            entries
          </label>
        </div>
        <div
          id="pp_search_filter"
          className="dataTables_filter table-top-search"
        >
          <label>
            Search:
            <input
              onChange={handleTableSearch}
              type="search"
              className="table-top-input"
              placeholder=""
              aria-controls="pp_search"
            />
          </label>
          {/*<button
            className="icon-button ml-3"
            onClick={clearAllFilters}
            disabled={!isFiltered}
          >
            <FontAwesomeIcon
              size="lg"
              icon={faFilterCircleXmark}
              color={isFiltered ? "#858796" : "#e3e6f0"}
            />
          </button>*/}
        </div>
      </div>
      <div className="ag-theme-alpine">
        {loading ? (
          <div className="d-flex justify-content-center align-items-center h-100">
            <p>Loading Data... </p>
          </div>
        ) : (
          <>
            <AgGridReact
              domLayout="autoHeight"
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              suppressRowClickSelection
              pagination={true}
              paginationPageSize={pageSize}
              onPaginationChanged={onPaginationChanged}
              onFilterChanged={onFilterChanged}
              unSortIcon
              suppressPaginationPanel
              overlayNoRowsTemplate={overlayNoRowsTemplate}
            ></AgGridReact>
          </>
        )}
      </div>
      <TablePagination
        gridRef={gridRef}
        pageSize={pageSize}
        currentPage={currentPage}
        totalPages={totalPages}
        totalEntries={totalEntries}
      />
    </>
  );
};

export default AdminLogsTable;
