import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  formatArtworkIntoOption,
  formatArtworkOptions,
  formatChannelIntoOption,
  formatChannelOptions,
  formatFlavorScentIntoOption,
  formatFlavorScentOptions,
  formatGlobalProductIntoOption,
  formatGlobalProductOptions,
  formatIsRoyaltyIntoOption,
  formatIsRoyaltyOptions,
  formatLicensedPropertyIntoOption,
  formatLicensedPropertyOptions,
  formatLicensorIntoOption,
  formatLicensorOptions,
  formatLifecycleStatusIntoOption,
  formatLifecycleStatusOptions,
  formatMarketingMaterialIntoOption,
  formatMarketingMaterialOptions,
  formatProductColorIntoOption,
  formatProductColorOptions,
  getGlobalProductCode,
  getTccCode,
} from "../../../helpers";
import { ILicensedProperty, ITcc } from "../../../types/data.interface";
import ConfirmValueModal from "../../ConfirmValueModal";
import OptionFormatter from "../../OptionFormatter";
import Tooltip from "../../Tooltip";
import useArtwork, { fetchArtwork } from "../../../hooks/useArtwork";
import { Predicates } from "../../../libraries/predicates/predicates";
import useAxios from "../../../utils/useAxios";
import { URL } from "../../../libraries/http/url";
import { toast } from "react-toastify";

export const TccEditCreateForm = ({
  item,
  updateCreateItem,
  errors,
  isEdit,
}: {
  item: ITcc;
  updateCreateItem: any;
  errors: any;
  isEdit: boolean;
}) => {
  const {
    globalProducts,
    tccs,
    marketingMaterials,
    flavorScents,
    channels,
    licensors,
    licensedProperties,
    colors,
    artworks,
  } = useOutletContext<any>();
  const parentType = "Global Product";

  const [gpnSelected, setGpnSelected] = useState(item.global_product);
  const [productColorSelected, setProductColorSelected] = useState(
    item.product_color
  );
  const [marketingMaterialSelected, setMarketingMaterialSelected] = useState(
    item.marketing_material
  );
  const [artworkSelected, setArtworkSelected] = useState(item.artwork);
  const [channelSelected, setChannelSelected] = useState(item.channel);
  const [licensorSelected, setLicensorSelected] = useState(
    item.licensed_property?.licensor
  );
  const [licensedPropertySelected, setLicensedPropertySelected] = useState(
    item.licensed_property
  );
  const [licensedPropertyOptions, setLicensedPropertyOptions] =
    useState(licensedProperties);
  const [isRoyaltySelected, setIsRoyaltySelected] = useState(
    item.licensed_property?.licensor?.is_royalty
  );
  const [flavorScentSelected, setFlavorScentSelected] = useState(
    item.flavor_scent
  );
  const [
    productionLifecycleStatusSelected,
    setProductionLifecycleStatusSelected,
  ] = useState(item.production_lifecycle_status ?? "Active");
  const [salesLifecycleStatusSelected, setSalesLifecycleStatusSelected] =
    useState(item.sales_lifecycle_status ?? "Active");

  const [newItem, setNewItem] = useState<ITcc>({
    ...item,
    pieces_in_selling_units: item.pieces_in_selling_units
      ? item.pieces_in_selling_units
      : 0,
    global_standard_cost: item.global_standard_cost
      ? item.global_standard_cost
      : 0,
    global_manufacturer_suggested_retail_price:
      item.global_manufacturer_suggested_retail_price
        ? item.global_manufacturer_suggested_retail_price
        : 0,
    upc_12_digits_us: item.upc_12_digits_us ? item.upc_12_digits_us : "",
    gtin_14_digits_row: item.gtin_14_digits_row ? item.gtin_14_digits_row : "",
  });

  const [showModal, setShowModal] = useState(false);
  const [existingItem, setExistingItem] = useState<ITcc | null>(null);
  const [nameWasChanged, setNameWasChanged] = useState(false);

  const axios = useAxios();

  const artworkData = useArtwork({});

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const existing = tccs.filter(
      (item: ITcc) => newItem.id !== item.id && newItem.name === item.name
    )[0];
    setExistingItem(existing);
    if (existing && (!isEdit || nameWasChanged)) {
      return setShowModal(true);
    } else {
      submit();
    }
  };

  const submit = async () => {
    const payload = {
      id: newItem.id,
      type: "Tupperware Consumer Code",
      name: newItem.name,
      global_product: newItem.global_product ? newItem.global_product.id : null,
      suffix: newItem.suffix,
      product_color_id: newItem.product_color_id,
      marketing_material: newItem.marketing_material
        ? newItem.marketing_material.id
        : null,
      artwork_id: newItem.artwork_id,
      artwork_deco_tech_id: newItem.artwork_deco_tech_id,
      channel: newItem.channel ? newItem.channel.id : null,
      pieces_in_selling_units: newItem.pieces_in_selling_units
        ? newItem.pieces_in_selling_units
        : null,
      consumer_facing_name: newItem.consumer_facing_name,
      licensed_property: newItem.licensed_property
        ? newItem.licensed_property.id
        : null,
      flavor_scent: newItem.flavor_scent ? newItem.flavor_scent.id : null,
      global_standard_cost: newItem.global_standard_cost,
      global_manufacturer_suggested_retail_price:
        newItem.global_manufacturer_suggested_retail_price,
      upc_12_digits_us: newItem.upc_12_digits_us,
      gtin_14_digits_row: newItem.gtin_14_digits_row,
      production_lifecycle_status:
        newItem.production_lifecycle_status ?? "Active",
      sales_lifecycle_status: newItem.sales_lifecycle_status ?? "Active",
    };
    await updateCreateItem(payload, "/tccs/");
  };

  const handleGpnValue = (e: any) => {
    if (e) {
      setGpnSelected(e.value);
      setNewItem({ ...newItem, global_product: e.value });
    } else {
      setGpnSelected(null);
      setNewItem({ ...newItem, global_product: null });
    }
  };

  const handleSuffixValue = (e: any) => {
    const cleanedSuffix = e.target.value.replace(/[^0-9]+/g, "");
    setNewItem({ ...newItem, suffix: cleanedSuffix });
  };

  const handleNameValue = (e: any) => {
    setNameWasChanged(true);
    setNewItem({ ...newItem, name: e.target.value });
  };

  const handleProductColorValue = (e: any) => {
    if (e) {
      setProductColorSelected(e.value);
      setNewItem({ ...newItem, product_color_id: e.value.id });
    } else {
      setProductColorSelected(null);
      setNewItem({ ...newItem, product_color_id: null });
    }
  };

  const handleMarketingMaterialValue = (e: any) => {
    if (e) {
      setMarketingMaterialSelected(e.value);
      setNewItem({ ...newItem, marketing_material: e.value });
    } else {
      setMarketingMaterialSelected(null);
      setNewItem({ ...newItem, marketing_material: null });
    }
  };

  const handleArtworkValue = (e: any) => {
    if (e) {
      setArtworkSelected(e.value);
      console.log(e.value);
      setNewItem({
        ...newItem,
        artwork_id: e.value.id,
        artwork_deco_tech_id: e.value.deco_tech_id,
      });
    } else {
      setArtworkSelected(null);
      setNewItem({ ...newItem, artwork_id: null, artwork_deco_tech_id: null });
    }
  };

  const handleChannelValue = (e: any) => {
    if (e) {
      setChannelSelected(e.value);
      setNewItem({ ...newItem, channel: e.value });
    } else {
      setChannelSelected(null);
      setNewItem({ ...newItem, channel: null });
    }
  };

  const handlePiecesInSellingUnitsValue = (e: any) => {
    setNewItem({
      ...newItem,
      pieces_in_selling_units: e.target.value.replace(/\D+/g, ""),
    });
  };

  const handleConsumerFacingNameValue = (e: any) => {
    console.log(e);
    setNewItem({ ...newItem, consumer_facing_name: e.target.value });
  };

  const handleLicensorValue = (e: any) => {
    if (e) {
      setLicensorSelected(e.value);
      setLicensedPropertySelected(null);
      setLicensedPropertyOptions(
        licensedProperties.filter(
          (item: ILicensedProperty) => item.licensor?.id == e.value.id
        )
      );
      setIsRoyaltySelected(e.value.is_royalty);
    } else {
      setLicensorSelected(null);
      setLicensedPropertySelected(null);
      setLicensedPropertyOptions(licensedProperties);
      setIsRoyaltySelected(null);
    }
  };

  const handleLicensedPropertyValue = (e: any) => {
    if (e) {
      setLicensedPropertySelected(e.value);
      setNewItem({ ...newItem, licensed_property: e.value });
    } else {
      setLicensedPropertySelected(null);
      setNewItem({ ...newItem, licensed_property: null });
    }
  };

  const handleIsRoyalyValue = (e: any) => {
    setIsRoyaltySelected(e.value);
  };

  const handleFlavorScentValue = (e: any) => {
    if (e) {
      setFlavorScentSelected(e.value);
      setNewItem({ ...newItem, flavor_scent: e.value });
    } else {
      setFlavorScentSelected(null);
      setNewItem({ ...newItem, flavor_scent: null });
    }
  };

  const handleGlobalStandardCostValue = (e: any) => {
    const regexPattern = /^[0-9]*\.?([0-9])*$/;
    if (regexPattern.test(e.target.value)) {
      setNewItem({
        ...newItem,
        global_standard_cost: e.target.value,
      });
    }
  };

  const handleGlobalManufacturerSuggestedRetailPriceValue = (e: any) => {
    const regexPattern = /^[0-9]*\.?([0-9])*$/;
    if (regexPattern.test(e.target.value)) {
      setNewItem({
        ...newItem,
        global_manufacturer_suggested_retail_price: e.target.value,
      });
    }
  };

  const handleUpc12DigitsUsValue = (e: any) => {
    setNewItem({
      ...newItem,
      upc_12_digits_us: e.target.value.replace(/\D+/g, ""),
    });
  };

  const handleGtin14DigitsRowValue = (e: any) => {
    setNewItem({
      ...newItem,
      gtin_14_digits_row: e.target.value.replace(/\D+/g, ""),
    });
  };

  const handleProductionLifecycleValue = (e: any) => {
    if (e) {
      setProductionLifecycleStatusSelected(e.value);
      setNewItem({ ...newItem, production_lifecycle_status: e.value });
    } else {
      setProductionLifecycleStatusSelected("");
      setNewItem({ ...newItem, production_lifecycle_status: null });
    }
  };

  const handleSalesLifecycleValue = (e: any) => {
    if (e) {
      setSalesLifecycleStatusSelected(e.value);
      setNewItem({ ...newItem, sales_lifecycle_status: e.value });
    } else {
      setSalesLifecycleStatusSelected("");
      setNewItem({ ...newItem, sales_lifecycle_status: null });
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const artworksOptions = async (search: string) => {
    if (Predicates.isNullOrUndefined(search) || search.length < 3) return [];
    const response = await fetchArtwork({
      search,
      axios,
    });
    return formatArtworkOptions(response);
  };

  const loadGpsBasedOnSearch = (input: string, callback: any) => {
    if (input.length < 3) {
      return callback([]);
    }

    const optionsToSearchFrom = formatGlobalProductOptions(globalProducts);
    const optionsFound =
      optionsToSearchFrom
        ?.filter((gpOption) =>
          gpOption.label.toLowerCase().includes(input.toLowerCase())
        )
        .slice(0, 100) ?? [];
    callback(optionsFound);
  };

  useEffect(() => {
    const fillTccSuffixSuggestion = async () => {
      const searchParams = URL.createSearchParams({
        type: "T",
        global_product_id: gpnSelected?.id,
      });
      try {
        const response = await axios.get<string>(
          `/new-suffix/?${searchParams.toString()}`
        );
        const newSuffix: string = response.data;

        if (newSuffix === "-1") {
          toast.warning(
            "There might not exist any more available suffixes for this Global Product"
          );
          setNewItem({ ...newItem, suffix: "" });
        } else {
          setNewItem({ ...newItem, suffix: newSuffix });
        }
      } catch (err) {
        toast.error(
          "Something unexpected happened while retrieving the next suffix value. Proceed normally"
        );
        setNewItem({ ...newItem, suffix: "" });
      }
    };

    if (
      (Predicates.isNullOrUndefined(item.global_product) &&
        Predicates.isNotNullAndNotUndefined(gpnSelected)) ||
      (Predicates.isNotNullAndNotUndefined(item.global_product) &&
        gpnSelected?.id !== item.global_product.id)
    ) {
      fillTccSuffixSuggestion();
    } else if (
      Predicates.isNotNullAndNotUndefined(gpnSelected) &&
      Predicates.isNotNullAndNotUndefined(item.global_product) &&
      gpnSelected.id === item.global_product.id
    ) {
      setNewItem({ ...newItem, suffix: item.suffix });
    }
  }, [gpnSelected]);

  return (
    <>
      <ReactModal
        isOpen={showModal}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <ConfirmValueModal
          type={"Tupperware Consumer Code"}
          valueThatExists={newItem.name}
          newItemDescription={`${getTccCode(newItem)} - ${newItem?.name}`}
          existingItemDescription={`${getTccCode(existingItem)} - ${
            existingItem?.name
          }`}
          handleCloseModal={closeModal}
          confirmSubmit={submit}
        />
      </ReactModal>
      <h6 className="mb-3 mt-3 border-bottom fw-bold fs-5">TCC Definition</h6>

      <form onSubmit={handleSubmit}>
        <div className="row form-row">
          <div className="col-md-4 form-group">
            <label className="form-label">Hierarchy Type</label>
            <Select
              value={{
                label: parentType,
                value: parentType,
              }}
              isDisabled
              classNamePrefix="react-select"
              placeholder=""
              menuIsOpen={false}
              isSearchable={false}
            />
          </div>
          <div className="col-md-8 form-group">
            <label className="form-label">Global Product</label>
            <Tooltip isEdit={isEdit} type={"Tupperware Consumer Code"} />
            <AsyncSelect
              onChange={handleGpnValue}
              defaultOptions={[]}
              loadOptions={loadGpsBasedOnSearch}
              noOptionsMessage={() => (
                <p className="mb-0">
                  Search for the Code or Name of the desired Global Product
                </p>
              )}
              value={
                gpnSelected ? formatGlobalProductIntoOption(gpnSelected) : null
              }
              classNamePrefix="react-select"
              className={errors.global_product ? "is-invalid" : ""}
              placeholder="(min 3 characters)"
              isClearable
              formatOptionLabel={OptionFormatter}
              isSearchable
              isLoading={globalProducts.length == 0}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-3 form-group">
            <label className="form-label">Global Product Code</label>
            <input
              value={getGlobalProductCode(gpnSelected)}
              disabled={true}
              className={"form-control"}
              type="text"
            />
          </div>
          <div className="col-md-1 form-group">
            <label className="form-label">Suffix</label>
            <input
              value={newItem.suffix}
              onChange={handleSuffixValue}
              className={
                errors.suffix ? "is-invalid form-control" : "form-control"
              }
              type="text"
              maxLength={4}
            />
          </div>

          <div className="col-md-8 form-group">
            <label className="form-label">Tupperware Consumer Code Name</label>
            <input
              value={newItem.name}
              onChange={handleNameValue}
              className={
                errors.name ? "is-invalid form-control" : "form-control"
              }
              type="text"
            />
          </div>
        </div>

        <h6 className="mb-3 mt-3 border-bottom fw-bold fs-5">TCC Attributes</h6>

        <div className="row form-row">
          <div className="col-md-3 form-group">
            <label className="form-label">Product Color </label>
            <Select
              onChange={handleProductColorValue}
              formatOptionLabel={OptionFormatter}
              value={
                productColorSelected
                  ? formatProductColorIntoOption(productColorSelected)
                  : null
              }
              options={formatProductColorOptions(colors)}
              classNamePrefix="react-select"
              className={errors.product_color_id ? "is-invalid" : ""}
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Material (Marketing) </label>
            <Select
              onChange={handleMarketingMaterialValue}
              formatOptionLabel={OptionFormatter}
              value={
                marketingMaterialSelected
                  ? formatMarketingMaterialIntoOption(marketingMaterialSelected)
                  : null
              }
              options={formatMarketingMaterialOptions(marketingMaterials)}
              classNamePrefix="react-select"
              placeholder=""
              className={errors.marketing_material ? "is-invalid" : ""}
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Artwork</label>
            <AsyncSelect
              name="artwork"
              value={
                artworkSelected
                  ? formatArtworkIntoOption(artworkSelected)
                  : null
              }
              onChange={handleArtworkValue}
              defaultOptions={formatArtworkOptions(artworkData)}
              loadOptions={artworksOptions}
              formatOptionLabel={OptionFormatter}
              cacheOptions
              isClearable
              isSearchable
              placeholder="(min 3 digits)"
              classNamePrefix="react-select"
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Channel</label>
            <Select
              onChange={handleChannelValue}
              formatOptionLabel={OptionFormatter}
              value={
                channelSelected
                  ? formatChannelIntoOption(channelSelected)
                  : null
              }
              options={formatChannelOptions(channels)}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              className={errors.channel ? "is-invalid" : ""}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-3 form-group">
            <label className="form-label">
              No of Pieces in Selling Unit (Sets)
            </label>
            <input
              value={
                newItem.pieces_in_selling_units
                  ? newItem.pieces_in_selling_units
                  : ""
              }
              onChange={handlePiecesInSellingUnitsValue}
              className={
                errors.pieces_in_selling_units
                  ? "is-invalid form-control"
                  : "form-control"
              }
              type="text"
              maxLength={3}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Consumer Facing Name</label>
            <input
              value={newItem.consumer_facing_name}
              onChange={handleConsumerFacingNameValue}
              className={
                errors.consumer_facing_name
                  ? "is-invalid form-control"
                  : "form-control"
              }
              type="text"
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Licensor </label>
            <Select
              onChange={handleLicensorValue}
              formatOptionLabel={OptionFormatter}
              value={
                licensorSelected
                  ? formatLicensorIntoOption(licensorSelected)
                  : null
              }
              options={formatLicensorOptions(licensors)}
              classNamePrefix="react-select"
              isClearable
              placeholder=""
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
          <div className="col-md-3 form-group">
            <label className="form-label">Licensed Property </label>
            <Select
              onChange={handleLicensedPropertyValue}
              formatOptionLabel={OptionFormatter}
              value={
                licensedPropertySelected
                  ? formatLicensedPropertyIntoOption(licensedPropertySelected)
                  : null
              }
              options={formatLicensedPropertyOptions(licensedPropertyOptions)}
              classNamePrefix="react-select"
              isDisabled={Predicates.isNullOrUndefined(licensorSelected)}
              isClearable
              placeholder=""
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-3 form-group">
            <label className="form-label">Royalty Item </label>
            <Select
              onChange={handleIsRoyalyValue}
              formatOptionLabel={OptionFormatter}
              value={
                isRoyaltySelected != null
                  ? formatIsRoyaltyIntoOption(isRoyaltySelected)
                  : null
              }
              options={formatIsRoyaltyOptions()}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isDisabled
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Flavor/Scent </label>
            <Select
              onChange={handleFlavorScentValue}
              formatOptionLabel={OptionFormatter}
              value={
                flavorScentSelected
                  ? formatFlavorScentIntoOption(flavorScentSelected)
                  : null
              }
              options={formatFlavorScentOptions(flavorScents)}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              // isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>

          <div className="col-md-3 form-group">
            <label className="form-label">Global Standard Cost</label>
            <input
              value={
                newItem.global_standard_cost ? newItem.global_standard_cost : ""
              }
              onChange={handleGlobalStandardCostValue}
              className={
                errors.global_standard_cost
                  ? "is-invalid form-control"
                  : "form-control"
              }
              type="text"
              maxLength={10}
            />
          </div>
          <div className="col-md-3 form-group">
            <label className="form-label">
              Global Manufacturer´s Suggested Retail Price (MSRP)
            </label>
            <input
              value={
                newItem.global_manufacturer_suggested_retail_price
                  ? newItem.global_manufacturer_suggested_retail_price
                  : ""
              }
              onChange={handleGlobalManufacturerSuggestedRetailPriceValue}
              className={
                errors.global_manufacturer_suggested_retail_price
                  ? "is-invalid form-control"
                  : "form-control"
              }
              type="text"
              maxLength={10}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md-3 form-group">
            <label className="form-label">UPC 12 Digits US</label>
            <input
              value={newItem.upc_12_digits_us ? newItem.upc_12_digits_us : ""}
              onChange={handleUpc12DigitsUsValue}
              className={
                errors.upc_12_digits_us
                  ? "is-invalid form-control"
                  : "form-control"
              }
              type="text"
              maxLength={12}
            />
          </div>
          <div className="col-md-3 form-group">
            <label className="form-label">GTIN 14 Digits RoW</label>
            <input
              value={newItem.gtin_14_digits_row}
              onChange={handleGtin14DigitsRowValue}
              className={
                errors.gtin_14_digits_row
                  ? "is-invalid form-control"
                  : "form-control"
              }
              type="text"
              maxLength={14}
            />
          </div>
          <div className="col-md-3 form-group">
            <label className="form-label">Production Lifecycle Status</label>
            <Select
              onChange={handleProductionLifecycleValue}
              formatOptionLabel={OptionFormatter}
              value={
                productionLifecycleStatusSelected
                  ? formatLifecycleStatusIntoOption(
                      productionLifecycleStatusSelected
                    )
                  : null
              }
              options={formatLifecycleStatusOptions()}
              classNamePrefix="react-select"
              placeholder=""
              className={errors.production_lifecycle_status ? "is-invalid" : ""}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
          <div className="col-md-3 form-group">
            <label className="form-label">Sales Lifecycle Status</label>
            <Select
              onChange={handleSalesLifecycleValue}
              formatOptionLabel={OptionFormatter}
              value={
                salesLifecycleStatusSelected
                  ? formatLifecycleStatusIntoOption(
                      salesLifecycleStatusSelected
                    )
                  : null
              }
              options={formatLifecycleStatusOptions()}
              classNamePrefix="react-select"
              placeholder=""
              className={errors.sales_lifecycle_status ? "is-invalid" : ""}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
        </div>

        <div className="row form-row">
          <div className="col-md form-group mt-4">
            <input type="submit" className="btn btn-primary" value="Save" />
          </div>
        </div>
      </form>
    </>
  );
};
