import { AxiosInstance } from "axios";
import { Predicates } from "../libraries/predicates/predicates";
import { URL } from "../libraries/http/url";
import { SearchParams } from "../types/search-params";
import { PageableOrType } from "../types/pageable";
import { IArtwork } from "../types/data.interface";
import { useEffect, useState } from "react";
import useAxios from "../utils/useAxios";

type ArtworkResponse = PageableOrType<IArtwork[]>;

export const fetchArtwork = async ({
  search: description,
  axios,
}: SearchParams & { axios: AxiosInstance }) => {
  try {
    const searchParams = URL.createSearchParams({
      description,
      limit: 100,
    });

    const response = await axios.get<ArtworkResponse>(
      `/product-master/designs/?${searchParams.toString()}`
    );

    return Predicates.parsePageableReponseToType(response);
  } catch (err) {
    console.log(err);
  }

  return [];
};

export default function useArtwork({ search }: SearchParams) {
  const [data, setData] = useState<IArtwork[]>([]);
  const axios = useAxios();

  useEffect(() => {
    const getData = async () => {
      const response = await fetchArtwork({ search, axios });
      setData(response);
    };
    getData();
  }, [search]);

  return data;
}
