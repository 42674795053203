import { useState } from "react";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { getPageAcronymByType } from "../helpers";

const Tooltip = (props: any) => {
  const [tooltip, showTooltip] = useState(true);
  const tooltipMessages: any = {
    "dc": {
      edit: "Sub-Category can only be associated to an existing Category. To create a new Category, please go in the Creation mode.",
      create: "Sub-Category can only be associated to an existing Category.",
    },
    "pmh": {
      edit: "Packing Material Sub-Type can only be associated to an existing Packing Material Type. To create a new Packing Material Type, please go in the Creation mode.",
      create: "Packing Material Sub-Type can only be associated to an existing Packing Material Type.",
    },
    "tcc": {
      edit: "Tupperware Consumer Code can only be associated to an existing Global Product. To create a new Global Product, please go in Creation mode.",
      create: "Tupperware Consumer Code can only be associated to an existing Global Product.",
    },
  };

  const editKey = props.isEdit ? "edit" : "create";
  const message = tooltipMessages[getPageAcronymByType(props.type)][editKey];
  return (
    <>
      {tooltip && (
        <ReactTooltip {...props} place="bottom" type="light" effect="solid" />
      )}
      <span
        className="tooltip-span"
        data-tip={message}
        onMouseEnter={() => showTooltip(true)}
        onMouseLeave={() => {
          showTooltip(false);
          setTimeout(() => showTooltip(true), 50);
        }}
      >
        <FontAwesomeIcon icon={faCircleInfo} className="tooltip-icon" />
      </span>
    </>
  );
};

export default Tooltip;
