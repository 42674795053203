import { NavLink, useMatch } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

function SideNav({ showMenu, toggle }: { showMenu: boolean; toggle: any }) {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;

  const adminActiveLink = useMatch("/admin") ? "nav-item active" : "nav-item";
  const GPHActiveLink = useMatch("/") ? "nav-item active" : "nav-item";
  const CreateActiveLink = useMatch("/create-new")
    ? "nav-item active"
    : "nav-item";
  const EditActiveLink = useMatch("/edit/*") ? "nav-item active" : "nav-item";
  const userGuideActiveLink = useMatch("/user-guide")
    ? "nav-item active"
    : "nav-item";
  return (
    <ul
      className={`navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${
        showMenu ? "" : "toggled"
      }`}
      id="accordionSidebar"
    >
      {/* Sidebar - Brand */}
      <NavLink
        className="sidebar-brand d-flex align-items-center justify-content-center"
        to="/"
      >
        <div className="sidebar-brand-icon">
          <i className="fas fa-home"></i>
        </div>
        {/* <div className="sidebar-brand-text mx-3">Home</div> */}
      </NavLink>

      {/* Divider */}
      <hr className="sidebar-divider" />

      {hasPermission("api.add_datacatalog") ||
      hasPermission("api.add_packingmaterialhier") ? (
        <>
          {/* Nav Item - Global Product Hierarchy (extended access) */}
          <li
            className={`${GPHActiveLink} ${CreateActiveLink} ${EditActiveLink}`}
          >
            <div
              className="nav-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i className="fas fa-fw fa-database"></i>
              <span>Global Product Hierarchy</span>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <NavLink
                  className="collapse-item"
                  to="/"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Search
                </NavLink>
                <NavLink
                  className="collapse-item"
                  to="/create-new"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Create
                </NavLink>
              </div>
            </div>
          </li>
        </>
      ) : (
        <>
          {/* Nav Item - Global Product Hierarchy (basic access) */}
          <li className={GPHActiveLink}>
            <NavLink className="nav-link" to="/">
              <i className="fas fa-fw fa-database"></i>
              <span>Global Product Hierarchy</span>
            </NavLink>
          </li>
        </>
      )}

      {hasPermission("api.logs_gph_datacatalog") ? (
        <>
          {/* Nav Item - Admin */}
          <li className={adminActiveLink}>
            <NavLink className="nav-link" to="/admin">
              <i className="fas fa-fw fa-user-shield"></i>
              <span>Admin Logs</span>
            </NavLink>
          </li>
        </>
      ) : null}

      {/* Nav Item - User Guide */}
      {/* <li className={userGuideActiveLink}>
        <NavLink className="nav-link" to="/user-guide">
          <i className="fas fa-fw fa-book"></i>
          <span>User Guide</span>
        </NavLink>
      </li> */}

      {/* Divider */}
      <hr className="sidebar-divider d-none d-md-block" />

      {/* Sidebar Toggler (Sidebar) */}
      <div className="text-center d-none d-md-inline">
        <button
          className="rounded-circle border-0"
          onClick={toggle}
          id="sidebarToggle"
        ></button>
      </div>
    </ul>
  );
}

export default SideNav;
