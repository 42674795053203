import Select from "react-select";
import OptionFormatter from "../../OptionFormatter";
import { useEffect, useState } from "react";
import { IGlobalProduct, ITcc } from "../../../types/data.interface";
import {
  formatGlobalProductIntoOption,
  formatGlobalProductOptions,
  formatTccIntoOption,
  formatTccsOptions,
} from "../../../helpers";
import AsyncSelect from "react-select/async";

export const TccFilterForm = ({
  tccs,
  globalProducts,
  typeSelected,
  setFilteredValues,
}: {
  tccs: ITcc[];
  globalProducts: IGlobalProduct[];
  typeSelected: string;
  setFilteredValues: any;
}) => {
  const parentType: string = "Global Product";

  const [tccValue, setTccValue] = useState<{
    label: string;
    value: ITcc;
  } | null>(null);
  const [associatedGlobalProductValue, setAssociatedGlobalProductValue] =
    useState<{
      label: string;
      value: IGlobalProduct;
    } | null>(null);

  const [tccValueOptions, setTccValueOptions] = useState<
    {
      label: string;
      value: ITcc;
    }[]
  >();
  const [globalProductValueOptions, setGlobalProductValueOptions] = useState<
    {
      label: string;
      value: IGlobalProduct;
    }[]
  >();

  useEffect(() => {
    setTccValue(null);
    setAssociatedGlobalProductValue(null);
    setTccValueOptions(formatTccsOptions(tccs));
    setGlobalProductValueOptions(formatGlobalProductOptions(globalProducts));
  }, [typeSelected, tccs, globalProducts]);

  useEffect(() => {
    let filteredValues: ITcc[] = tccs;
    if (tccValue) {
      filteredValues = filteredValues.filter(
        (item) => item.id === tccValue.value.id
      );
    }

    if (associatedGlobalProductValue) {
      filteredValues = filteredValues.filter((item) => {
        if (item.global_product) {
          return (
            item.global_product.id === associatedGlobalProductValue.value.id
          );
        }
        return false;
      });
    }

    setFilteredValues(filteredValues);
  }, [tccValue, associatedGlobalProductValue]);

  const handleTccValue = (e: any) => {
    if (e) {
      setTccValue(e);
      const globalProduct: IGlobalProduct = e.value.global_product;
      if (globalProduct) {
        setAssociatedGlobalProductValue(
          formatGlobalProductIntoOption(globalProduct)
        );
      }
    } else {
      setTccValue(null);
      setGlobalProductValueOptions(formatGlobalProductOptions(globalProducts));
    }
  };

  const handleAssociatedGlobalProductValue = (e: any) => {
    if (e) {
      setTccValue(null);
      setAssociatedGlobalProductValue(formatGlobalProductIntoOption(e.value));
      setTccValueOptions(
        tccs
          .filter((item) => item.global_product?.id === e.value.id)
          .map(formatTccIntoOption)
      );
    } else {
      setAssociatedGlobalProductValue(null);
      setTccValue(null);
      setTccValueOptions(formatTccsOptions(tccs));
    }
  };

  const loadTccsBasedOnSearch = (input: string, callback: any) => {
    if (input.length < 3) {
      return callback([]);
    }

    const optionsToSearchFrom = !associatedGlobalProductValue
      ? tccs.map(formatTccIntoOption)
      : tccValueOptions;
    const optionsFound =
      optionsToSearchFrom
        ?.filter((tccOption) =>
          tccOption.label.toLowerCase().includes(input.toLowerCase())
        )
        .slice(0, 100) ?? [];
    callback(optionsFound);
  };

  const loadGpsBasedOnSearch = (input: string, callback: any) => {
    if (input.length < 3) {
      return callback([]);
    }

    const optionsToSearchFrom = globalProductValueOptions;
    const optionsFound =
      optionsToSearchFrom
        ?.filter((gpOption) =>
          gpOption.label.toLowerCase().includes(input.toLowerCase())
        )
        .slice(0, 100) ?? [];
    callback(optionsFound);
  };

  return (
    <>
      <div className="row form-row align-items-end">
        <div className="col-md-10 form-group">
          <label className="form-label">Code / Name</label>
          <AsyncSelect
            onChange={handleTccValue}
            defaultOptions={
              !associatedGlobalProductValue ? [] : tccValueOptions
            }
            loadOptions={loadTccsBasedOnSearch}
            noOptionsMessage={
              !associatedGlobalProductValue
                ? () => (
                    <p className="mb-0">
                      Search for the Code or Name of the desired TCC
                    </p>
                  )
                : () => <p className="mb-0">No options</p>
            }
            value={tccValue}
            formatOptionLabel={OptionFormatter}
            classNamePrefix="react-select"
            placeholder="(min 3 characters)"
            isClearable
            isSearchable
            isLoading={
              !associatedGlobalProductValue && tccValueOptions?.length == 0
            }
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
      <div className="row align-items-end form-row">
        <div className="col-md-3 form-group">
          <label className="form-label">Hierarchy Type</label>
          <Select
            value={{
              value: parentType,
              label: parentType,
            }}
            isDisabled
            classNamePrefix="react-select"
            placeholder=""
            menuIsOpen={false}
            isSearchable={false}
          />
        </div>
        <div className="col-md-7 form-group">
          <label className="form-label">Global Product Code / Name</label>
          <AsyncSelect
            onChange={handleAssociatedGlobalProductValue}
            defaultOptions={
              !associatedGlobalProductValue
                ? []
                : [associatedGlobalProductValue]
            }
            loadOptions={loadGpsBasedOnSearch}
            noOptionsMessage={() => (
              <p className="mb-0">
                Search for the Code or Name of the desired Global Product
              </p>
            )}
            value={associatedGlobalProductValue}
            classNamePrefix="react-select"
            placeholder="(min 3 characters)"
            isClearable
            formatOptionLabel={OptionFormatter}
            isSearchable
            isLoading={globalProductValueOptions?.length == 0}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
      </div>
    </>
  );
};
