import { Predicates } from "../predicates/predicates";

export class URL {
  static createSearchParams<T extends Object>(data: T): URLSearchParams {
    const searchParams = new URLSearchParams();

    for (const [key, value] of Object.entries(data)) {
      if (Predicates.isNotNullAndNotUndefined(value))
        searchParams.append(key, value.toString());
    }

    return searchParams;
  }
}
