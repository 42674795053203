import { AxiosResponse } from "axios";
import { PageableDTO } from "../../types/pageable";

export class Predicates {
  /**
   * Predicate makes sure the value is null or is not undefined.
   *
   * @param t any object
   */
  static isNullOrUndefined = <T,>(
    t: T | null | undefined
  ): t is null | undefined => t === undefined || t === null;

  /**
   * Predicate makes sure the value is not null and is not undefined.
   *
   * @param t any object
   */
  static isNotNullAndNotUndefined = <T,>(t: T | null | undefined): t is T =>
    t !== undefined && t !== null;

  /**
   * Predicate makes sure the value is null, undefined or empty.
   *
   * @param t
   * @returns
   */
  static isNullOrUndefinedOrEmpty = <T,>(t: T | null | undefined): t is T =>
    t === undefined || t === null || Predicates.isEmpty(t);

  /**
   * Predicate makes sure the value is not null, undefined or empty.
   *
   * @param t
   * @returns
   */
  static isNotNullAndNotUndefinedAndNotEmpty = <T,>(
    t: T | null | undefined
  ): t is T => t !== undefined && t !== null && Predicates.isNotEmpty(t);

  /**
   * Predicate makes sure the value is empty.
   *
   * @param t
   * @returns
   */
  static isEmpty = <T,>(t: T): t is T =>
    (Array.isArray(t) && t.length === 0) ||
    (typeof t === "string" && t.trim().length === 0);

  /**
   * Predicate makes sure the value is not empty.
   * @param t
   * @returns
   */
  static isNotEmpty = <T,>(t: T): t is T =>
    (Array.isArray(t) && t.length > 0) ||
    (typeof t === "string" && t.trim().length > 0);

  /**
   * Predicate makes sure the object is type of Pageable.
   *
   * @param t any object
   */
  static isPageable = <T,>(t: T | PageableDTO<T>): t is PageableDTO<T> =>
    Object.getOwnPropertyNames(t).includes("results");

  /**
   * Predicate converts the type AxiosResponse<T | Pageable<T>> to T
   *
   * @param t AxiosResponse<T | Pageable<T>>
   * @returns T
   */
  static parsePageableReponseToType = <T,>(
    t: AxiosResponse<T | PageableDTO<T>>
  ): T => {
    if (Predicates.isPageable(t.data)) {
      return t.data.results;
    }
    return t.data;
  };
}
