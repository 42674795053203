import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning, faClose } from "@fortawesome/free-solid-svg-icons";

const ConfirmValueModal = ({
  confirmSubmit,
  handleCloseModal,
  type,
  valueThatExists,
  newItemDescription,
  existingItemDescription
}: {
  confirmSubmit: any;
  handleCloseModal: any;
  type: string;
  valueThatExists: string;
  newItemDescription: string;
  existingItemDescription: string;
}) => {
  const onCancel = () => {
    handleCloseModal();
  };
  const onConfirm = () => {
    confirmSubmit();
    handleCloseModal();
  };
  return (
    <div className="p-3">
      <div className="d-flex justify-content-between mb-5">
        <h5 className="mb-0">Global Product Hierarchy</h5>
        <FontAwesomeIcon onClick={onCancel} icon={faClose} />
      </div>
      <div className="text-center px-5">
        <FontAwesomeIcon icon={faWarning} size="2x" className="mb-4" />
        <p className="text-center mb-5">
          A {type} with value {valueThatExists} already exists (
          {existingItemDescription}). <br /> Do you wish to
          save the {type} "{newItemDescription}"?
        </p>
        <div className="d-flex justify-content-center align-center">
          <button onClick={onConfirm} type="button" className="btn btn-primary">
            Save changes
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn-secondary ml-3"
            data-dismiss="modal"
          >
            No and edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmValueModal;
