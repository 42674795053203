import { useState, useEffect, useContext } from "react";
import Select from "react-select";
import AuthContext from "../context/AuthContext";
import {
  getTypeOptionsBasedOnPermissions,
  isDatacatalogType,
  isGlobalProductType,
  isPackingType,
  isTccType,
} from "../helpers";

import { DatacatalogFilterForm } from "./specific_filter_forms/datacatalog_forms/DatacatalogFilterForm";
import { PmHierFilterForm } from "./specific_filter_forms/pmhier_forms/PmHierFilterForm";
import { GpnFilterForm } from "./specific_filter_forms/gpn_tcc_forms/GpnFilterForm";
import { IGlobalProduct, ITcc } from "../types/data.interface";
import { TccFilterForm } from "./specific_filter_forms/gpn_tcc_forms/TccFilterForm";

const GPHFilters = ({
  data,
  gps,
  tccs,
  isLoading,
  handleTableFilter,
}: {
  data: any[];
  gps: IGlobalProduct[];
  tccs: ITcc[];
  isLoading: boolean;
  handleTableFilter: any;
}) => {
  const authCtx = useContext(AuthContext);
  const user = authCtx?.user;
  const hasPermission = authCtx?.hasPermission;

  const [typeOptions, setTypeOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const [typeSelected, setTypeSelected] = useState<any>(null);

  const [currentFilteredData, setCurrentFilteredData] = useState<any[]>([]);
  const [activeFilterForm, setActiveFilterForm] = useState<any>(null);

  const onTypeChange = (e: any) => {
    if (e) {
      setTypeSelected({ label: e.value, value: e.value });
      setActiveFilterForm(getFormComponent(e.value));
      setCurrentFilteredData(data.filter((item) => item.type === e.value));
    } else {
      setTypeSelected(null);
      setActiveFilterForm(null);
      setCurrentFilteredData([]);
    }
  };

  const getFormComponent = (type: string) => {
    if (isDatacatalogType(type))
      return (
        <DatacatalogFilterForm
          data={data.filter((item) => isDatacatalogType(item.type))}
          typeSelected={type}
          setFilteredValues={setCurrentFilteredData}
        />
      );
    if (isPackingType(type))
      return (
        <PmHierFilterForm
          data={data.filter((item) => isPackingType(item.type))}
          typeSelected={type}
          setFilteredValues={setCurrentFilteredData}
        />
      );
    if (isGlobalProductType(type))
      return (
        <GpnFilterForm
          globalProducts={gps}
          typeSelected={type}
          setFilteredValues={setCurrentFilteredData}
        />
      );
    if (isTccType(type))
      return (
        <TccFilterForm
          tccs={tccs}
          globalProducts={gps}
          typeSelected={type}
          setFilteredValues={setCurrentFilteredData}
        />
      );

    return null;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleTableFilter(typeSelected.value, currentFilteredData);
  };

  useEffect(() => {
    setTypeOptions(getTypeOptionsBasedOnPermissions(hasPermission));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (typeSelected) {
      setActiveFilterForm(getFormComponent(typeSelected.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gps, tccs, data]);

  return (
    <div className="py-3 mb-2">
      <form className="gx-3 gy-2" onSubmit={handleSubmit}>
        <div className="row form-row align-items-end">
          <div className="col-md-6 form-group">
            <label className="form-label">Type</label>
            <Select
              onChange={onTypeChange}
              options={isLoading ? [] : typeOptions}
              value={typeSelected}
              classNamePrefix="react-select"
              placeholder=""
              isClearable
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
                LoadingIndicator: () => null,
              }}
            />
          </div>
        </div>
        {activeFilterForm}
        <div className="row form-row align-items-end">
          <div className="col-md form-group">
            <input
              type="submit"
              className="btn btn-primary"
              value="Search"
              disabled={!typeSelected}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default GPHFilters;
