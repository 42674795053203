const NoAccessPage = () => {
  return (
    <div className="text-center container">
      <h3 className="mt-5">You are not authorized to access this page.</h3>
      <p>Please authenticate through the mold app.</p>
    </div>
  );
};

export default NoAccessPage;
