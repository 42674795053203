import Select from "react-select";
import OptionFormatter from "../../OptionFormatter";
import { useEffect, useState } from "react";
import { IPmHier } from "../../../types/data.interface";
import { formatPmHierIntoOption, getParentType } from "../../../helpers";

export const PmHierFilterForm = ({
    data,
    typeSelected,
    setFilteredValues,
}: {
    data: IPmHier[];
    typeSelected: string;
    setFilteredValues: any;
}) => {
    const parentType: string = "Packing Material Type";

    const [packingMaterialValue, setPackingMaterialValue] = useState<{
        label: string;
        value: IPmHier;
    } | null>(null);
    const [packingMaterialParentValue, setPackingMaterialParentValue] = useState<{
        label: string;
        value: IPmHier;
    } | null>(null);

    const [packingMaterialValueOptions, setPackingMaterialValueOptions] = useState<{
        label: string;
        value: IPmHier;
    }[]>();
    const [packingMaterialParentValueOptions, setPackingMaterialParentValueOptions] = useState<{
        label: string;
        value: IPmHier;
    }[]>();
    const [showHierOptions, setShowHierOptions] = useState(false);

    useEffect(() => {
        setPackingMaterialValue(null);
        setPackingMaterialParentValue(null);
        setPackingMaterialValueOptions(data.filter((item) => item.type === typeSelected).map(formatPmHierIntoOption));
        setPackingMaterialParentValueOptions(data.filter((item) => item.type === parentType).map(formatPmHierIntoOption));

        setShowHierOptions(getParentType(typeSelected).length > 0);
    }, [typeSelected]);

    useEffect(() => {
        let filteredValues: IPmHier[] = data.filter((item) => item.type === typeSelected);
        if (packingMaterialValue) {
            filteredValues = filteredValues.filter((item) => item.id === packingMaterialValue.value.id);
        }

        if (packingMaterialParentValue) {
            filteredValues = filteredValues.filter((item) => {
                if (item.parent) {
                    return item.parent.id === packingMaterialParentValue.value.id;
                }
                return false;
            });
        }

        setFilteredValues(filteredValues);
    }, [packingMaterialValue, packingMaterialParentValue]);

    const handlePackingMaterialValue = (e: any) => {
        if (e) {
            setPackingMaterialValue(e);
            const parent: IPmHier = e.value.parent;
            if (parent) {
                setPackingMaterialParentValue(formatPmHierIntoOption(parent));
            }
        } else {
            setPackingMaterialValue(null);
            setPackingMaterialParentValueOptions(data.filter((item) => item.type === parentType).map(formatPmHierIntoOption));
        }
    };

    const handlePackingMaterialParentValue = (e: any) => {
        if (e) {
            setPackingMaterialValue(null);
            setPackingMaterialParentValue(formatPmHierIntoOption(e.value));
            setPackingMaterialValueOptions(data.filter((item) => item.type === typeSelected && item.parent?.id === e.value.id).map(formatPmHierIntoOption));
        } else {
            setPackingMaterialParentValue(null);
            setPackingMaterialValue(null);
            setPackingMaterialValueOptions(data.filter((item) => item.type === typeSelected).map(formatPmHierIntoOption));
        }
    };

    return (
        <>
            <div className="row form-row align-items-end">
                <div className="col-md-10 form-group">
                    <label className="form-label">Acronym / Value</label>
                    <Select
                        onChange={handlePackingMaterialValue}
                        options={packingMaterialValueOptions}
                        value={packingMaterialValue}
                        formatOptionLabel={OptionFormatter}
                        classNamePrefix="react-select"
                        placeholder=""
                        isClearable
                        isSearchable
                        components={{
                            IndicatorSeparator: () => null
                        }}
                    />
                </div>
            </div>
            <div className="row align-items-end form-row">
                {showHierOptions && (
                    <>
                        <div className="col-md-3 form-group">
                            <label className="form-label">Hierarchy Type</label>
                            <Select
                                value={
                                    {
                                        value: parentType,
                                        label: parentType
                                    }
                                }
                                isDisabled
                                classNamePrefix="react-select"
                                placeholder=""
                                menuIsOpen={false}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col-md-7 form-group">
                            <label className="form-label">Packing Material Type Acronym / Value</label>
                            <Select
                                onChange={handlePackingMaterialParentValue}
                                options={packingMaterialParentValueOptions}
                                value={packingMaterialParentValue}
                                classNamePrefix="react-select"
                                placeholder=""
                                isClearable
                                formatOptionLabel={OptionFormatter}
                                isSearchable
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}