import { toast } from "react-toastify";
import EditCreateForm from "../components/EditCreateForm";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";

import { useState, useEffect, useContext } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import { fillItemRelatedObjects, getGenericEmptyObject } from "../helpers";

const CreateItemPage = () => {
  const authCtx = useContext(AuthContext);
  const hasPermission = authCtx?.hasPermission;
  const { user, refetchData, combData } = useOutletContext<any>();
  const navigate = useNavigate();
  const axios = useAxios();

  const [errorObject, setErrorObject] = useState({});
  const [item, setItem] = useState<any>(getGenericEmptyObject());

  const toastSuccess = (type: string, acronym: string, name: string) =>
    toast.success(`Successfully created ${type} ${acronym} - ${name}`);
  const toastErrors = (errors: any) => {
    for (const key in errors) {
      errors[key].forEach((message: string) => {
        toast.error(message);
      });
    }
  };

  const createItem = async (newItem: any, endpoint: string) => {
    try {
      const response = await axios({
        method: "post",
        url: endpoint,
        data: newItem,
      });
      if (response && response.status === 201) {
        toastSuccess(newItem.type ?? "", newItem.acronym ?? "", newItem.name);
        setItem(getGenericEmptyObject());
        setErrorObject({});
        refetchData();
      }
      return response;
    } catch (err: any) {
      setItem(fillItemRelatedObjects(newItem, combData));
      setErrorObject(err.response.data);
      toastErrors(err.response.data);
      return err;
    }
  };

  useEffect(() => {
    if (
      !hasPermission("api.add_datacatalog") &&
      !hasPermission("api.add_packingmaterialhier")
    )
      return navigate("/no-access");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <EditCreateForm
      isEdit={false}
      item={item}
      updateCreateItem={createItem}
      errors={errorObject}
      setErrors={setErrorObject}
    />
  );
};

export default CreateItemPage;
