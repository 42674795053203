import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { IPmHier } from "../../../types/data.interface";
import { formatPmHierIntoOption, formatPmHierOptions } from "../../../helpers";
import Select from "react-select";
import OptionFormatter from "../../OptionFormatter";
import Tooltip from "../../Tooltip";
import ConfirmValueModal from "../../ConfirmValueModal";
import ReactModal from "react-modal";

export const PmHierEditCreateForm = ({
    isEdit,
    item,
    updateCreateItem,
    errors,
}: {
    isEdit: boolean;
    item: IPmHier;
    updateCreateItem: any;
    errors: any;
}) => {
    const parentType: string = "Packing Material Type";
    const { pmhier } = useOutletContext<any>();
    const packingMaterialTypeOptions = pmhier.filter((item: IPmHier) => item.type == parentType);
    const [showModal, setShowModal] = useState(false);

    const [newItem, setNewItem] = useState<IPmHier>(item);
    const [showPackingMaterialTypeOptions, setShowPackingMaterialTypeOptions] = useState(false);
    const [packingMaterialTypeSelected, setPackingMaterialTypeSelected] = useState<IPmHier | null>(item.parent);

    const [existingItem, setExistingItem] = useState<IPmHier | null>(null);
    const [valueWasChanged, setValueWasChanged] = useState(false);

    useEffect(() => {
        setNewItem(item);
        if (item.type === "Packing Material Sub-Type") {
            setShowPackingMaterialTypeOptions(true);
            setPackingMaterialTypeSelected(item.parent);
        } else {
            setShowPackingMaterialTypeOptions(false);
        }
    }, [item])

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const existing = pmhier.filter(
            (item: IPmHier) =>
                newItem.id !== item.id &&
                newItem.name === item.name &&
                newItem.type === item.type
        )[0];
        setExistingItem(existing);
        if (existing && (!isEdit || valueWasChanged)) {
            return setShowModal(true);
        } else {
            submit();
        }
    };

    const submit = async () => {
        const payload = {
            id: newItem.id,
            name: newItem.name,
            active: newItem.active,
            type: newItem.type,
            parent: newItem.parent ? newItem.parent.id : null,
            code: newItem.code,
        };
        await updateCreateItem(payload, "/packing-material-hier/");
    };

    const handleActiveCheck = () => {
        setNewItem({ ...newItem, active: !newItem?.active });
    };

    const handleAcronym = (e: any) => {
        const cleanedAcronym = e.target.value.replace(/[^a-z]/gi, "").toUpperCase();
        setNewItem({ ...newItem, code: cleanedAcronym });
    };

    const handleValue = (e: any) => {
        setValueWasChanged(true);
        const cleanedValue = e.target.value.replace(/[^a-z0-9- '():_&,"#/]/gi, "");
        setNewItem({ ...newItem, name: cleanedValue });
    };

    const handlePackingMaterialTypeValue = (e: any) => {
        if (e) {
            setPackingMaterialTypeSelected(e.value);
            setNewItem({
                ...newItem,
                parent: e.value,
            });
        } else {
            setPackingMaterialTypeSelected(null);
            setNewItem({
                ...newItem,
                parent: null,
            });
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <>
            <ReactModal
                isOpen={showModal}
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <ConfirmValueModal
                    type={newItem.type}
                    valueThatExists={newItem.name}
                    newItemDescription={`${newItem?.code} - ${newItem?.name}`}
                    existingItemDescription={`${existingItem?.code} - ${existingItem?.name}`}
                    handleCloseModal={closeModal}
                    confirmSubmit={submit}
                />
            </ReactModal>

            <form onSubmit={handleSubmit}>
                <div className="row align-items-end form-row">
                    {showPackingMaterialTypeOptions ? (
                        <>
                            <div className="col-md-4 form-group">
                                <label className="form-label">Hierarchy Type</label>
                                <Select
                                    value={{
                                        label: parentType,
                                        value: parentType,
                                    }}
                                    isDisabled
                                    classNamePrefix="react-select"
                                    placeholder=""
                                    menuIsOpen={false}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="col-md-6 form-group">
                                <label className="form-label">Acronym / Value</label>
                                <Tooltip isEdit={isEdit} type={parentType} />
                                <Select
                                    onChange={handlePackingMaterialTypeValue}
                                    value={packingMaterialTypeSelected ? formatPmHierIntoOption(packingMaterialTypeSelected) : null}
                                    options={formatPmHierOptions(packingMaterialTypeOptions)}
                                    isSearchable
                                    formatOptionLabel={OptionFormatter}
                                    classNamePrefix="react-select"
                                    className={errors.parent ? "is-invalid" : ""}
                                    placeholder=""
                                    isClearable
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                />
                            </div>
                        </>
                    ) : null}
                </div>

                <div className="row form-row">
                    <div className="col-md-4 form-group">
                        <label className="form-label">Acronym</label>
                        <input
                            value={newItem.code}
                            onChange={handleAcronym}
                            className={
                                errors.code ? "is-invalid form-control" : "form-control"
                            }
                            type="text"
                            maxLength={3}
                        />
                    </div>

                    <div className="col-md-6 form-group">
                        <label className="form-label">Value</label>
                        <input
                            value={newItem.name}
                            onChange={handleValue}
                            className={
                                errors.name ? "is-invalid form-control" : "form-control"
                            }
                            type="text"
                        />
                    </div>

                    <div className="col-md-1 form-group d-flex flex-column justify-content-center text-center">
                        <label className="form-label ">Active</label>
                        <div className="fixed-checkbox-height">
                            <input
                                checked={newItem.active}
                                onChange={handleActiveCheck}
                                className="checkbox"
                                type="checkbox"
                            />
                        </div>
                    </div>
                </div>

                <div className="row form-row">
                    <div className="col-md form-group mt-4">
                        <input type="submit" className="btn btn-primary" value="Save" />
                    </div>
                </div>
            </form>
        </>

    )
}