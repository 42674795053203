import AdminLogsTable from "../components/AdminLogsTable";
import Select from "react-select";
import { mapYearOptions, currentYear } from "../helpers";
import { useState, useEffect, useContext } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";

const AdminPage = () => {
  const authCtx = useContext(AuthContext);
  const { user } = useOutletContext<any>();
  const hasPermission = authCtx?.hasPermission;
  const axios = useAxios();

  const navigate = useNavigate();
  const [adminLogs, setAdminLogs] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [yearSelected, setYearSelected] = useState({
    value: currentYear,
    label: currentYear
  });

  const fetchAsync = async (year = currentYear) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`gph-admin-logs/?year=${year}`);
      setAdminLogs(response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const onYearChange = (e: any) => {
    if (e) {
      setYearSelected(e);
      fetchAsync(e.value);
    }
  };

  useEffect(() => {
    if (!hasPermission("api.logs_gph_datacatalog"))
      return navigate("/no-access");
    fetchAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, hasPermission]);

  return (
    <div>
      <div className="card shadow mb-4">
        <div className="card-header py-3 d-flex justify-content-between align-items-center">
          <h5 className="my-1 text-primary">Admin Logs</h5>
          <div className="d-flex align-items-center">
            <label className="m-0 mr-1">Year:</label>
            <Select
              className="year-select"
              classNamePrefix="react-select"
              onChange={onYearChange}
              options={mapYearOptions()}
              value={yearSelected}
              components={{
                IndicatorSeparator: () => null
              }}
            />
          </div>
        </div>
        <div className="card-body">
          <AdminLogsTable data={adminLogs} loading={isLoading} />
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
