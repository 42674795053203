const UserGuideBase = ({
  showPm,
  showDc
}: {
  showPm: boolean;
  showDc: boolean;
}) => {
  return (
    <div className="guide">
      <h1>User Guide</h1>
      <div className="table-of-content">
        {showDc ? (
          <>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-TW-MDM-GPH(Basicrole)-AuthenticationtoGlobalProductHierarchy">
                  Authentication to Global Product Hierarchy
                </a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-GPH(Basicrole)-Search">Search</a>
              </li>
              <li>
                <a href="#UserManual-TW-MDM-GPH(Basicrole)-ChangesintheMoldApp">
                  Changes in the MoldApp
                </a>
                <ul className="toc-indentation">
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Basicrole)-PISApplication">
                      PIS Application
                    </a>
                    <ul className="toc-indentation">
                      <li>
                        <a href="#UserManual-TW-MDM-GPH(Basicrole)-ProductInformationscreen">
                          Product Information screen
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#UserManual-TW-MDM-GPH(Basicrole)-ProductMaster">
                      Product Master
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </>
        ) : null}
        {showPm ? (
          <>
            <h3>Packing Materials</h3>
            <ul className="toc-indentation">
              <li>
                <a href="#UserManual-GPH-PackingMaterials-AuthenticationtoGlobalProductHierarchy">
                  Authentication to Global Product Hierarchy
                </a>
              </li>
              <li>
                <a href="#UserManual-GPH-PackingMaterials-Search">Search</a>
              </li>
            </ul>
          </>
        ) : null}
      </div>
      {showDc ? (
        <div>
          <h2 id="UserManual-TW-MDM-GPH(Basicrole)-AuthenticationtoGlobalProductHierarchy">
            Authentication to Global Product Hierarchy
          </h2>
          <p>
            Authentication should be done through the
            <strong> Mold Applications Main Menu</strong> &gt;
            <strong> Controls section.</strong> Clicking once on the
            <strong> Global Product Hierarchy</strong> hyperlink will start the
            authentication process. When this process ends, a new tab will open
            redirecting to the
            <strong> Global Product Hierarchy</strong> application.
          </p>
          <img
            alt="Global Product Hierarchy link"
            className="image image-medium"
            src={process.env.PUBLIC_URL + "/userguide/basic/46956599.png"}
          />
          <span className="image-caption">Global Product Hierarchy link</span>
          <h2 id="UserManual-TW-MDM-GPH(Basicrole)-Search">Search</h2>
          <p>
            In order to find Hierarchy Types, such as Category, Sub-Category and
            Product Line, select
            <strong> Global Product Hierarchy</strong> on the sidebar menu.
          </p>
          <p>
            You can search for Category, Sub-Category and Product Line using
            multiple criteria by filling some or all of the fields
            <em> Type</em> and <em>Code/Value</em>, with values and clicking on
            the
            <strong> Search</strong> button on the right.
          </p>
          <img
            alt="Auto-complete field"
            className="image"
            src={process.env.PUBLIC_URL + "/userguide/basic/47349767.png"}
          />
          <span className="image-caption">Auto-complete field</span>
          <ul>
            <li>
              <p>
                If selecting <strong>Category or Product Line </strong>as Type,
                only the first search line will appear and all existing values
                will be available in the auto-complete <em>Code/Value</em>{" "}
                field.
              </p>
            </li>
            <li>
              <p>
                If selecting <strong>Sub-Category </strong>as Type, the second
                line with Hierarchy Type will automatically appear with Category
                as Hierarchy Type. <em>Code/Value</em> field is auto-complete;
                if a Sub-Category value is selected, only corresponding
                Categories will be retrieved in <em>Hierarchy Code/Value</em>{" "}
                field; the same applies on the other way for Sub-Categories
                values if a Category value is already selected.
              </p>
            </li>
          </ul>
          <p>
            It is also possible to filter Hierarchy Types by filling the
            <em> search </em>input on the top-right of the table with criteria.
          </p>
          <img
            alt="Retrieve all sub-categories from a specific Category"
            className="image"
            src={process.env.PUBLIC_URL + "/userguide/basic/47251493.png"}
          />
          <span className="image-caption">
            Retrieve all sub-categories from a specific Category
          </span>
          <img
            alt="Search and sorting options"
            className="image"
            src={process.env.PUBLIC_URL + "/userguide/basic/46858350.png"}
          />
          <span className="image-caption">Search and sorting options</span>
          <p>
            As displayed in the recommendation message at the bottom of the
            page, if any Category, Sub-Category or Product Line is missing, a
            request can be sent to Sophie Wolters or Monica Dominguez to create
            them.
          </p>
          <img
            alt="New Hierarchy Type creation - information message"
            className="image"
            src={process.env.PUBLIC_URL + "/userguide/basic/47218697.png"}
          />
          <span className="image-caption">
            New Hierarchy Type creation - information message
          </span>
          <h2 id="UserManual-TW-MDM-GPH(Basicrole)-ChangesintheMoldApp">
            Changes in the MoldApp
          </h2>
          <h3 id="UserManual-TW-MDM-GPH(Basicrole)-PISApplication">
            PIS Application
          </h3>
          <p>
            To access to the Product Information screen, click on the
            <strong> PIS Application</strong> link and then
            <strong> Create New PIS</strong>.
          </p>
          <img
            alt="PIS Application link"
            className="image image-medium"
            src={process.env.PUBLIC_URL + "/userguide/basic/46956551.png"}
          />
          <span className="image-caption">PIS Application link</span>
          <img
            alt="Create New PIS link"
            className="image image-medium"
            src={process.env.PUBLIC_URL + "/userguide/basic/46956557.png"}
          />
          <span className="image-caption">Create New PIS link</span>
          <h4 id="UserManual-TW-MDM-GPH(Basicrole)-ProductInformationscreen">
            Product Information screen
          </h4>
          <p>
            “Category and Class” and “Brand Group and Name” fields were changed
            to
            <strong> Category</strong>, <strong>Sub-Category</strong> and
            <strong> Product Line</strong> mandatory fields, and
            <strong> Global Product Name</strong> field was added.
          </p>
          <img
            alt="New fields in Create new PIS"
            className="image image-medium"
            src={process.env.PUBLIC_URL + "/userguide/basic/46956563.png"}
          />
          <span className="image-caption">New fields in Create new PIS</span>
          <img
            alt="Sub-Category can only be selected when a Category is choosen, and the Sub-Categories list depends on the Category"
            className="image image-medium"
            src={process.env.PUBLIC_URL + "/userguide/basic/46956569.png"}
          />
          <span className="image-caption">
            Sub-Category can only be selected when a Category is choosen, and
            the Sub-Categories list depends on the Category
          </span>
          <img
            alt="Mandatory messages disappear when data is filled"
            className="image image-medium"
            src={process.env.PUBLIC_URL + "/userguide/basic/46956575.png"}
          />
          <span className="image-caption">
            Mandatory messages disappear when data is filled
          </span>
          <h3 id="UserManual-TW-MDM-GPH(Basicrole)-ProductMaster">
            Product Master
          </h3>
          <p>
            To access to the <strong> Product Information</strong> screen, click
            on
            <strong> Product Master</strong> link and search for a product.
          </p>
          <img
            alt="Product Master link"
            className="image image-medium"
            src={process.env.PUBLIC_URL + "/userguide/basic/46956581.png"}
          />
          <span className="image-caption">Product Master link</span>
          <p>
            <strong>Category</strong>, <strong> Sub-Category</strong> and
            <strong>Product Line</strong> fields were added on the top of the
            page and old
            <strong> Category, Class, Brand Group and Brand Name </strong>
            values are displayed at the bottom as “<strong>(Old)</strong>”.
          </p>
          <img
            alt="Product Information"
            className="image"
            src={process.env.PUBLIC_URL + "/userguide/basic/47022358.png"}
          />
          <span className="image-caption"></span>
          <img
            alt="Old and new values are displayed in the Product Information sheet"
            className="image"
            src={process.env.PUBLIC_URL + "/userguide/basic/47284317.png"}
          />
          <span className="image-caption">
            Old and new values are displayed in the Product Information sheet
          </span>
        </div>
      ) : null}

      {showPm ? (
        <div>
          <h1 className="mt-5">Packing Materials</h1>
          <h2 id="UserManual-GPH-PackingMaterials-AuthenticationtoGlobalProductHierarchy">
            Authentication to Global Product Hierarchy
          </h2>
          <p>
            Authentication should be done through the
            <strong> Mold Applications Main Menu</strong> &gt;
            <strong> Controls section.</strong> Clicking once on the
            <strong> Global Product Hierarchy</strong> hyperlink will start the
            authentication process. When this process ends, a new tab will open
            redirecting to the
            <strong> Global Product Hierarchy</strong> application.
          </p>

          <img
            className="image"
            alt="Global Product Hierarchy link"
            src={process.env.PUBLIC_URL + "/userguide/admin/58949638.png"}
          />
          <span className="image-caption">Global Product Hierarchy link</span>

          <h2 id="UserManual-GPH-PackingMaterials-Search">Search</h2>
          <p>
            In order to find
            <strong> Packing Material Types and Sub-Types</strong>, select
            <strong> Global Product Hierarchy</strong> &gt;
            <strong> Search</strong> on the sidebar menu.
          </p>

          <img
            className="image"
            alt=""
            src={process.env.PUBLIC_URL + "/userguide/admin/58982407.png"}
          />
          <span className="image-caption"></span>

          <p>
            You can search for
            <strong> Packing Material Type and/or Sub-Type</strong> using
            multiple criteria by filling some or all of the fields
            <em> Type</em> and <em> Code/Value</em> and clicking on the
            <strong> Search</strong> button on the right.
          </p>
          <p>
            It's also possible to filter Hierarchy Types by filling the
            <em> search </em>input on the top-right of the table with criteria.
          </p>

          <img
            className="image"
            alt="Auto-complete search field"
            src={process.env.PUBLIC_URL + "/userguide/admin/58687505.png"}
          />
          <span className="image-caption">Auto-complete search field</span>

          <img
            className="image"
            alt="Retrieve all Packing Material Sub-Types from a specific Packing Material Type"
            src={process.env.PUBLIC_URL + "/userguide/admin/58982413.png"}
          />
          <span className="image-caption">
            Retrieve all Packing Material Sub-Types from a specific Packing
            Material Type
          </span>

          <img
            className="image"
            alt="Search and sorting options"
            src={process.env.PUBLIC_URL + "/userguide/admin/59080711.png"}
          />
          <span className="image-caption">Search and sorting options</span>
        </div>
      ) : null}
    </div>
  );
};

export default UserGuideBase;
