import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getPageAcronymByType } from "../helpers";
const EditIcon = ({ data }: any) => {
  const typeparam = getPageAcronymByType(data.type);
  return (
    <Link to={`edit/${typeparam}/${data.id}`} className="icon-link">
      <FontAwesomeIcon icon={faEdit} /> Edit
    </Link>
  );
};

export default EditIcon;
