const spinnerWheelSrc =
  process.env.PUBLIC_URL + "/assets/img/spinner-wheel.gif";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <div>
        <img src={spinnerWheelSrc} alt="loading..." />
        <p className="text-center">Loading Data...</p>
      </div>
    </div>
  );
};

export default Spinner;
