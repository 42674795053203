import {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Predicates } from "../libraries/predicates/predicates";

interface AppContextInterface {
  authTokens: { refresh: string; access: string } | null;
  setAuthTokens?: any;
  user: any;
  username: string | null;
  setUser?: any;
  loginUser?: any;
  logoutUser?: any;
  hasPermission?: any;
}

const AuthContext = createContext<AppContextInterface | null>(null);

export default AuthContext;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [authTokens, setAuthTokens] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    return tokens ? JSON.parse(tokens) : null;
  });

  const [user, setUser] = useState(() => {
    const tokens = localStorage.getItem("authTokens");
    return tokens ? jwt_decode(tokens) : null;
  });

  const [username, setUsername] = useState(() => {
    const username = localStorage.getItem("username");
    return username ? JSON.parse(username) : null;
  });

  const [permissions, setPermissions] = useState(() => {
    const permissions = localStorage.getItem("permissions");
    return permissions ? permissions : null;
  });

  const getSessionId = useMemo(() => {
    const sessIdParams = searchParams.get("sessid");
    const sessIdStorage = sessionStorage.getItem("sessid");

    if (sessIdParams) {
      sessionStorage.setItem("sessid", sessIdParams);
    }
    return sessIdParams ?? sessIdStorage;
  }, [searchParams]);

  const hasSessionId = useMemo(() => {
    const sessIdParams = searchParams.get("sessid");
    const sessIdStorage = sessionStorage.getItem("sessid");
    return (
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(sessIdParams) ||
      Predicates.isNotNullAndNotUndefinedAndNotEmpty(sessIdStorage)
    );
  }, [searchParams]);

  const isSameSessionId = useMemo(() => {
    const sessIdParams = searchParams.get("sessid");
    const sessIdStorage = sessionStorage.getItem("sessid");
    return sessIdParams === sessIdStorage;
  }, [searchParams]);

  const hasPermission = useCallback(
    (permisssionToCheck: string) => {
      return permissions?.includes(permisssionToCheck);
    },
    [permissions]
  );

  const loginUser = useCallback(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/token/session/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sessid: getSessionId,
        }),
      }
    );
    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      setPermissions(data.permissions);
      setUsername(data.username);
      localStorage.setItem("authTokens", JSON.stringify(data));
      localStorage.setItem("permissions", JSON.stringify(data.permissions));
      localStorage.setItem("username", JSON.stringify(data.username));
    } else {
      logoutUser();
    }
  }, []);

  const logoutUser = useCallback((reroute = true) => {
    setAuthTokens(null);
    setUser(null);
    setUsername(null);
    setPermissions(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("permissions");
    localStorage.removeItem("username");
    if (reroute) {
      navigate("/no-access");
    }
  }, []);

  const contextData: AppContextInterface = useMemo(
    () => ({
      authTokens,
      setAuthTokens,
      user,
      username,
      setUser,
      loginUser,
      logoutUser,
      hasPermission,
    }),
    [authTokens, user, username, hasPermission, loginUser, logoutUser]
  );

  useEffect(() => {
    if (hasSessionId) {
      if (authTokens && isSameSessionId) {
        setUser(jwt_decode(authTokens.access));
        searchParams.delete("sessid");
        setSearchParams(searchParams);
      } else {
        loginUser();
      }
    } else {
      logoutUser();
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
